'use client';

import {
  type AbstractIntlMessages,
  NextIntlClientProvider,
  type useTimeZone,
} from 'next-intl';
import type { PropsWithChildren } from 'react';
import { defaultTranslationValues } from '@/i18n/request';
import type { LocaleCode } from '~/const/locale';

export const IntlProvider = ({
  locale,
  messages,
  children,
  timeZone,
}: PropsWithChildren<{
  locale: LocaleCode;
  messages: AbstractIntlMessages;
  timeZone: ReturnType<typeof useTimeZone>;
}>) => {
  return (
    <NextIntlClientProvider
      locale={locale}
      defaultTranslationValues={defaultTranslationValues}
      messages={messages}
      timeZone={timeZone}
      now={new Date()}
    >
      {children}
    </NextIntlClientProvider>
  );
};
