'use client';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { VpButton, VpIcon, VpIconButton } from '@vtmn-play/react/headless';
import type { GenericDialogPropsType } from './models';
import { Loader } from '~/components/commons/Loader/Loader';
import { Portal } from '~/components/commons/Portal/Portal';

export const GenericDialog = ({
  isPrimaryButtonDisabled = false,
  primaryButtonClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  secondaryButtonClick,
  onClose,
  content,
  hasFooter = true,
  isClosable = true,
  maskClosable = false,
  isLarge,
  isLoading = false,
  isOpen,
  isScrollable = false,
  title,
}: GenericDialogPropsType) => {
  const t = useTranslations('CONTACT_DIALOG');

  const handleClick = maskClosable && isClosable ? onClose : undefined;

  return isOpen
    ? (
      <Portal>
        <div
          className="dialog__overlay"
          data-testid="GenericDialog"
          onClick={handleClick}
          onKeyDown={e =>
            maskClosable && isClosable && e.key === 'Enter' && onClose}
          role="button"
          tabIndex={0}
        >
          <div
            data-testid="dialog"
            className={classNames('modal', 'show', {
              'modal-large': isLarge,
              'modal-scrollable': isScrollable,
            })}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <Loader loading={isLoading} size="medium">
                  <div className="modal-inner-wrapper">
                    {isClosable && (
                      <VpIconButton
                        data-testid="close-button"
                        onClick={onClose}
                        data-dismiss="modal"
                        aria-label={t('CLOSE')}
                        variant="tertiary"
                        size="small"
                      >
                        <VpIcon name="close" />
                      </VpIconButton>
                    )}
                    {title && (
                      <div className="modal-header">
                        <div className="modal-title vp-title-s">{title}</div>
                      </div>
                    )}
                    <div data-testid="ErrorDialog" className="modal-body">
                      {content}
                    </div>
                    {hasFooter && (
                      <div className="modal-footer" data-testid="footer">
                        {secondaryButtonTitle && secondaryButtonClick && (
                          <VpButton
                            data-testid="secondary-button"
                            variant="secondary"
                            size="medium"
                            shape="squared"
                            onClick={secondaryButtonClick}
                          >
                            {secondaryButtonTitle}
                          </VpButton>
                        )}
                        {primaryButtonTitle && (
                          <VpButton
                            data-cy="confirm-choice"
                            data-testid="confirm-choice"
                            disabled={isPrimaryButtonDisabled}
                            size="medium"
                            shape="squared"
                            onClick={primaryButtonClick}
                          >
                            {primaryButtonTitle}
                          </VpButton>
                        )}
                      </div>
                    )}
                  </div>
                </Loader>
              </div>
            </div>
          </div>
        </div>
      </Portal>
      )
    : null;
};
