export enum ApiRoute {
  ACCEPT_CURRENT_TERMS_OF_USE = 'accept-current-terms-of-use',
  ANONYMIZE = 'anonymize',
  CRITERION_CHOICE_USERS = 'criterion_choice_users',
  ANNOUNCEMENTS = 'announcements',
  ANSWERS = 'answers',
  CHILDREN = 'children',
  CHOICES = 'choices',
  COMMENTS = 'comments',
  CONTRIBUTIONS = 'contributions',
  CRITERIA = 'criteria',
  FOLLOWED_UP_PROJECTS = 'followed_up_projects',
  GUEST_ACCESS = 'guest_access',
  DATA = 'data',
  GUEST_USERS = 'guest_users',
  INTERACTIONS = 'interactions',
  ME = 'me',
  MEDIA = 'media',
  PROJECT_TAGS = 'project_tags',
  PROJECTS = 'projects',
  QUESTIONS = 'questions',
  RATING = 'rating',
  REACTIONS = 'reactions',
  REFRESH_TOKEN = 'refresh-token',
  SECTIONS = 'sections',
  SEND_MAIL = 'send_mail',
  SESSIONS = 'sessions',
  SIGNED_URL = 'signed_url',
  SPORTS = 'sports',
  SUBSCRIPTION = 'subscription',
  TERMS_OF_USES = 'terms_of_uses/current',
  TOKEN = 'token',
  TRANSLATION = 'translation',
  USER_INTERACTION_STATUS = 'user_interaction_status',
  USERS = 'users',
  UNSUBSCRIBE = 'unsubscribe',
}

type AnswersUriTemplate = `${ApiRoute.ANSWERS}/${number}`;

type CommentsUriTemplate = `${ApiRoute.COMMENTS}/${number}`;

type ContributionsUriTemplate = `${ApiRoute.CONTRIBUTIONS}/${number}`;

type CriteriaUriTemplate =
  | `${ApiRoute.CRITERIA}`
  | `${ApiRoute.CRITERIA}/${number}`
  | `${ApiRoute.CRITERIA}/${number}/${ApiRoute.CHOICES}`;

type FollowedUpUriTemplate = `${ApiRoute.FOLLOWED_UP_PROJECTS}/${number}`;

type GuestUriTemplate =
  | `${ApiRoute.GUEST_USERS}/${ApiRoute.ME}`
  | `${ApiRoute.GUEST_USERS}/${ApiRoute.TOKEN}`
  | `${ApiRoute.INTERACTIONS}/${number}/${ApiRoute.GUEST_ACCESS}`;

type InteractionsUriTemplate =
  | `${ApiRoute.INTERACTIONS | ApiRoute.ANNOUNCEMENTS}/${number}`
  | `${ApiRoute.INTERACTIONS}/${number}/${ApiRoute.SECTIONS}`;

type MailTemplate = `${ApiRoute.SEND_MAIL}`;

type ProjectsUriTemplate = `${ApiRoute.PROJECTS}/${number}`;

type QuestionsUriTemplate = `${ApiRoute.QUESTIONS}/${number}/${ApiRoute.ANSWERS}`;

type ReactionsUriTemplate = `${ApiRoute.REACTIONS}/${number}`;

type SectionsUriTemplate =
  | `${ApiRoute.SECTIONS}/${number}`
  | `${ApiRoute.SECTIONS}/${number}/${ApiRoute.QUESTIONS}`;

type SendMailUriTemplate = `${ApiRoute.SEND_MAIL}`;

type SessionUriTemplate = `${ApiRoute.SESSIONS}/${number}`;

type TermsOfUsesUriTemplate = `${ApiRoute.TERMS_OF_USES}`;

type TranslationUriTemplate = `${ApiRoute.TRANSLATION}/${ApiRoute.RATING}`;

type UserUriTemplate =
  | `${ApiRoute.USERS}`
  | `${ApiRoute.USERS}/${ApiRoute.TOKEN | ApiRoute.REFRESH_TOKEN}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.ANONYMIZE}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.CHILDREN}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.ACCEPT_CURRENT_TERMS_OF_USE}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.SPORTS}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.FOLLOWED_UP_PROJECTS}`
  | `${ApiRoute.USERS}/${number}/${ApiRoute.SUBSCRIPTION}?key=${string}`
  | `${ApiRoute.USERS}/${number}/${ApiRoute.UNSUBSCRIBE}`;

type UserDataTemplate = `${ApiRoute.USERS}/${number}/${ApiRoute.DATA}`;

type UserCriteriaUriTemplate =
  | `${ApiRoute.USERS}/${number}/${ApiRoute.CRITERION_CHOICE_USERS}`
  | `${ApiRoute.GUEST_USERS}/${number}/${ApiRoute.CRITERION_CHOICE_USERS}`
  | `${ApiRoute.CRITERION_CHOICE_USERS}/${number}`
  | `${ApiRoute.CRITERION_CHOICE_USERS}`;

type UserInteractionStatusUriTemplate = `${ApiRoute.USER_INTERACTION_STATUS}/${number}`;

export type UriTemplate =
  | `${ApiRoute}`
  | AnswersUriTemplate
  | CommentsUriTemplate
  | ContributionsUriTemplate
  | CriteriaUriTemplate
  | FollowedUpUriTemplate
  | GuestUriTemplate
  | InteractionsUriTemplate
  | MailTemplate
  | ProjectsUriTemplate
  | QuestionsUriTemplate
  | ReactionsUriTemplate
  | SectionsUriTemplate
  | SendMailUriTemplate
  | SessionUriTemplate
  | TermsOfUsesUriTemplate
  | TranslationUriTemplate
  | UserUriTemplate
  | UserDataTemplate
  | UserCriteriaUriTemplate
  | UserInteractionStatusUriTemplate;
