'use client';

import { useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import classNames from 'classnames';
import type { VpAssetName } from '@vtmn-play/react/headless';
import { VpAsset, VpIcon } from '@vtmn-play/react/headless';
import { useSearchParams } from 'next/navigation';
import { useApiErrorContext, useAuthenticatedContext } from '@/app/components/Provider';
import { PopperComponent } from '~/components/commons/Popper/PopperComponent';
import { CookieName } from '~/const/appConst';
import { Loader } from '@/app/components/Loader';
import {
  getLanguageFromLocale,
  getLocaleFromLanguage,
  setCookie,
} from '~/utils/utils';
import type { LocaleType } from '~/model/GlobalTypes';
import { type LocaleCode, defaultLocale, labelLocales, localeSubpaths } from '~/const/locale';
import {
  acceptLanguages,
  usePathname,
  useRouter,
} from '@/i18n/routing';
import { fetchItem } from '@/app/actions/server';
import type { UserType } from '~/model/UserType';

export const LanguageSwitcher = () => {
  const { user, isAuthenticated } = useAuthenticatedContext();
  const { setError } = useApiErrorContext();

  const router = useRouter();
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const t = useTranslations('NAVIGATION');

  const [loading, setLoading] = useState(false);
  const [isPopperOpened, setIsPopperOpened] = useState(false);
  const [currentLocale, setCurrentLocale] = useState<LocaleType>(() =>
    getLocaleFromLanguage((locale || defaultLocale) as LocaleCode, true),
  );

  const activeLanguage = user?.favorite_locale || currentLocale;

  const updateUserLocale = async (newUserLocale: LocaleType) => {
    try {
      setLoading(true);

      const response = await fetchItem<UserType>({
        pathname: 'users/me',
        method: 'PUT',
        revalidate: 0,
        body: {
          favorite_locale: newUserLocale,
        },
      });

      if (response['hydra:description']) {
        setLoading(false);
        return setError(response);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleChangeLocale = async (newLocale: LocaleType) => {
    // Store the chosen locale in a dedicated cookie
    // Update locale state accordingly
    setCurrentLocale(newLocale);
    // Update the user's favourite locale if different
    isAuthenticated && (await updateUserLocale(newLocale));

    setCookie(`${CookieName.COCREATION_LOCALE}=${newLocale}`);
    setCookie(`${CookieName.NEXT_LOCALE}=${getLanguageFromLocale(newLocale)}`);

    const search = searchParams.toString() ? `?${searchParams.toString()}` : '';

    router.replace(
      `${pathname}${search}`,
      { locale: localeSubpaths[newLocale] },
    );
  };

  return (
    <PopperComponent
      ariaLabel={`${t('LANGUAGE')} : ${labelLocales[activeLanguage]}`}
      arrow
      isOpen={isPopperOpened}
      placement="top"
      setIsOpen={setIsPopperOpened}
      text={(
        <span className="vp-link__label" data-part="label">
          <span data-part="end-slot">
            <VpAsset
              name={
              `flag-${(user?.favorite_locale || currentLocale).split('_')[1].toLowerCase()}` as VpAssetName
            }
              width={12}
            />
          </span>
          {' '}
          {labelLocales[activeLanguage]}
          <span data-part="end-slot">
            <VpIcon name={`chevron-${isPopperOpened ? 'up' : 'down'}`} />
          </span>
        </span>
      )}
    >
      <Loader loading={loading}>
        <ul tabIndex={-1}>
          {acceptLanguages.map((locale, index) => {
            return (
              <li key={locale} data-testid="language-item">
                <button
                  type="button"
                  id={locale}
                  className={classNames(
                    'popper__option',
                    {
                      'popper__option--active':
                      locale === (user?.favorite_locale || currentLocale),
                    },
                  )}
                  {...(index === 0 && { autoFocus: true })}
                  onClick={() => handleChangeLocale(locale)}
                >
                  <VpAsset
                    name={`flag-${locale.split('_')[1].toLowerCase()}` as VpAssetName}
                    width={20}
                  />
                  {' '}
                  {labelLocales[locale]}
                </button>
              </li>
            );
          })}
        </ul>
      </Loader>
    </PopperComponent>
  );
};
