'use client';

import { useTranslations } from 'next-intl';
import { getLogoutUri, getVerifyUri } from '@/app/actions/server/connect';
import { usePathname } from '@/i18n/routing';
import { GenericDialog } from '~/components/commons/genericDialog/GenericDialog';

export function BlockedAccountDialog() {
  const t = useTranslations();
  const pathname = usePathname();

  const handleRedirectToAccountVerification = async () => {
    await getVerifyUri(pathname);
  };

  const handleLogout = async () => {
    await getLogoutUri({});
  };

  return (
    <GenericDialog
      isOpen
      isClosable={false}
      content={(
        <div className="text-center">
          {t('ERROR_MESSAGE.BLOCKED')}
        </div>
      )}
      primaryButtonTitle={t('GLOBAL.VERIFY')}
      primaryButtonClick={handleRedirectToAccountVerification}
      secondaryButtonTitle={t('GLOBAL.LOGOUT')}
      secondaryButtonClick={handleLogout}
    />
  );
}
