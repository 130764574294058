import { useCallback, useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import type { Collection } from '@/app/actions/collection';
import { fetchCollection, fetchItem } from '@/app/actions/server';
import { useApiErrorContext, useAuthenticatedContext } from '@/app/components/Provider';
import { ApiRoute } from '@/app/utils/request/api-routes';
import type { SportType } from '~/model/SportType';
import { RequestStatus, useRequestState } from '~/hooks/request/useRequestState';
import { AcceptLanguage } from '~/const/locale';
import type { User } from '@/types/user/type';

export function useUserSports(child?: User) {
  const locale = useLocale();
  const [userSports, setUserSports] = useState<Collection<SportType>>();

  const { user, setUser } = useAuthenticatedContext();

  const { status, startRequest, handleSuccess, handleError } = useRequestState();
  const { setError } = useApiErrorContext();

  const handleFetchUserSports = useCallback(async () => {
    if (!user?.id) {
      return;
    }
    startRequest();

    const sports = await fetchCollection<SportType>({
      pathname: `${ApiRoute.USERS}/${child?.id || user.id}/${ApiRoute.SPORTS}`,
      revalidate: 0,
      headers: {
        acceptLanguage: AcceptLanguage[locale],
      },
      unpaginate: true,
    });

    if (sports['hydra:description']) {
      handleError();
      return setError(sports);
    }

    setUserSports(sports);
    handleSuccess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const save = async (
    sports: SportType[],
  ) => {
    const postData = {
      sports: sports.map(sport => sport['@id']),
      sportUpdatedAt: new Date(),
    };

    startRequest();
    const response = await fetchItem<User>({
      pathname: `users/${child?.id || user?.id}`,
      method: 'PUT',
      body: postData,
      headers: {
        acceptLanguage: AcceptLanguage[locale],
      },
    });

    if (response['hydra:description']) {
      handleError();
      return setError(response);
    }

    !child?.id && setUser(response);

    setUserSports(actual => ({
      ...actual,
      'hydra:member': sports,
    }));

    handleSuccess();
  };

  useEffect(() => {
    handleFetchUserSports();
  }, [handleFetchUserSports]);

  return {
    isFetching: status === RequestStatus.PENDING,
    userSports,
    save,
  };
}
