'use client';

import { VpButton } from '@vtmn-play/react/headless';
import { useTranslations } from 'next-intl';
import { useAuthenticatedContext } from '../Provider';
import { GuestModeDescription } from '~/components/commons/GuestModeAccess/GuestModeDescription';

export const AuthInfo = () => {
  const t = useTranslations();

  const { redirectToLogin } = useAuthenticatedContext();

  return (
    <section data-testid="auth-info" className="auth-info">
      <div className="auth-info__wrapper">
        <header className="auth-info__header vp-title-l">
          {t('GUEST_MODAL.TITLE')}
        </header>
        <div className="auth-info__body vp-body-m">
          <GuestModeDescription />
        </div>
        <footer className="auth-info__footer">
          <VpButton
            onClick={async () => await redirectToLogin()}
          >
            {t('GLOBAL.LOGIN')}
          </VpButton>
        </footer>
      </div>
    </section>
  );
};
