'use client';

import classNames from 'classnames';
import { type PropsWithChildren, createContext, useContext } from 'react';
import { usePathname } from '@/i18n/routing';
import { useScroll } from '~/hooks/scroll/useScroll';

export const ScrollContext = createContext({ isScrollingUp: false, scroll: 0 });

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (context === undefined || context === null) {
    throw new Error(
      'useScrollContext must be called within ScrollProvider',
    );
  }
  return context;
};

export const ScrollProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const value = useScroll();

  const pageName
    = (pathname === '/' && '-home')
    || pathname?.split('/').join('-').split('?').shift();

  return (
    <ScrollContext.Provider value={value}>
      <div
        data-testid="layout"
        className={classNames('page-layout', {
          [`page${pageName}`]: pageName,
          'not-scrolled': value.scroll === 0,
          scrolled: value.scroll > 0,
          'is-scrolling-up': value.isScrollingUp,
        })}
      >
        {children}
      </div>
    </ScrollContext.Provider>
  );
};
