'use client';

import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { PopperPlacementType } from '@mui/material';
import { ClickAwayListener, Popper } from '@mui/material';
import classNames from 'classnames';
import { keyboardKeys } from '~/const/keyCodes';

export const PopperComponent = ({
  arrow = false,
  ariaLabel,
  children,
  isOpen,
  placement,
  text,
  setIsOpen,
}: {
  ariaLabel?: string;
  arrow?: boolean;
  children: ReactNode;
  isOpen: boolean;
  placement?: PopperPlacementType;
  text: ReactNode;
  setIsOpen: (value: boolean) => void;
}) => {
  const [arrowRef, setArrowRef] = useState(null);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === keyboardKeys.ESCAPE) {
        setIsOpen(false);
        anchorEl.current?.focus();
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    if (isOpen)
      document.addEventListener('keydown', handleKeyPress);
    else
      document.removeEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, isOpen]);

  return (
    <>
      <button
        type="button"
        ref={anchorEl}
        className="popper__opener vp-link vp-link--small"
        data-part="link"
        {...(ariaLabel && { 'aria-label': ariaLabel })}
        aria-haspopup="listbox"
        onClick={handleClick}
      >
        {text}
      </button>
      <Popper
        anchorEl={anchorEl.current}
        role="listbox"
        className={classNames('popper', {
          'popper--with-arrow': arrow,
        })}
        disablePortal
        open={isOpen}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 16,
            },
          },
          {
            name: 'arrow',
            enabled: arrow,
            options: {
              element: arrowRef,
            },
          },
        ]}
        {...(placement && { placement })}
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className="popper__content">
            {arrow && (
              <div
                ref={setArrowRef}
                className="popper__arrow MuiPopper-arrow"
              />
            )}
            {children}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
