'use client';

import { type PropsWithChildren, createContext, useContext } from 'react';
import { type UserDataType, useUserData } from '@/app/hooks/useUserData';

const UserDataContext = createContext<ReturnType<typeof useUserData> | undefined>(undefined);

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);
  if (context === undefined || context === null) {
    throw new Error(
      'useUserDataContext must be called within UserDataProvider',
    );
  }
  return context;
};

export function UserDataProvider({
  children,
  filter,
}: PropsWithChildren<{
  filter?: Array<keyof UserDataType>;
}>) {
  const value = useUserData(filter);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}
