'use client';

import { type PropsWithChildren, createContext, useContext } from 'react';
import { useError } from '@/app/hooks/useError';
import { BlockedAccountDialog } from '@/app/components/Dialog/BlockedAccountDialog/BlockedAccountDialog';

const ApiErrorContext = createContext<ReturnType<typeof useError> | undefined>(undefined);

export const useApiErrorContext = () => {
  const context = useContext(ApiErrorContext);
  if (!context) {
    throw new Error(
      'useApiErrorContext must be called within ApiErrorContext',
    );
  }
  return context;
};

export const ApiErrorProvider = ({ children }: PropsWithChildren) => {
  const value = useError();

  return (
    <ApiErrorContext.Provider value={value}>
      {children}
      {!!value.error && value.error['hydra:description'] === 'The account is blocked' && (
        <BlockedAccountDialog />
      )}
    </ApiErrorContext.Provider>
  );
};
