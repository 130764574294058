'use client';

import type { VpIconName } from '@vtmn-play/react/headless';
import { VpIcon } from '@vtmn-play/react/headless';
import { useTranslations } from 'next-intl';
import { Link, usePathname } from '@/i18n/routing';

export const BackButton = ({
  icon = 'chevron-left',
  label,
  href,
  onClick,
}: {
  icon?: VpIconName;
  label?: string;
  href?: string;
  onClick?: () => void;
}) => {
  const t = useTranslations('GLOBAL.ACTIONS');
  const pathname = usePathname();
  const finalSlashIndex = pathname?.lastIndexOf('/');
  const previousPathname = pathname?.slice(0, finalSlashIndex) || '/';

  return (
    <Link
      data-testid="BackButton"
      data-cy="BackButton"
      className="back-button vp-button vp-button--tertiary vp-button--small button-wrapper-link"
      href={href || previousPathname}
      prefetch={false}
      {...onClick && { onClick }}
    >
      <span className="vp-button__label" data-part="label">
        {icon && (
          <div data-part="start-slot">
            <VpIcon name={icon} />
          </div>
        )}
        {label || t('BACK')}
      </span>
    </Link>
  );
};
