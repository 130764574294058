import { useEffect, useState } from 'react';
import { sessionStorageScrollerSuffix } from '~/components/utils';
import { useBreakpoint } from '~/hooks/match-media/useBreakpoint';
import { ScrollMemorisation } from '~/model/GlobalTypes';
import { removeSessionStorage } from '~/utils/utils';

export enum ScrollDirection {
  DOWN,
  UPSIDE,
}

export function useScroll() {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.UPSIDE);
  const [scroll, setScroll] = useState(0);

  const { isLBreakpoint } = useBreakpoint();

  const isScrollingUp = scrollDir === ScrollDirection.UPSIDE;

  useEffect(() => {
    const threshold = isLBreakpoint ? 60 : 80;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      const isScrollingDown = scrollY > lastScrollY;
      const belowThreshold = Math.abs(scrollY - lastScrollY) < threshold;

      setScroll(scrollY);

      if (isScrollingDown && belowThreshold) {
        ticking = false;
        return;
      }

      if (lastScrollY && (!belowThreshold || !isScrollingDown)) {
        setScrollDir(
          isScrollingDown ? ScrollDirection.DOWN : ScrollDirection.UPSIDE,
        );
      } else {
        setScrollDir(ScrollDirection.UPSIDE);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    removeSessionStorage(
      `${ScrollMemorisation.projects}${sessionStorageScrollerSuffix}`,
    );
    removeSessionStorage(
      `${ScrollMemorisation.home}${sessionStorageScrollerSuffix}`,
    );
  }, []);

  return { isScrollingUp, scroll };
}
