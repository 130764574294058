import { useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import { getErrorCodeFromUri } from '../utils/request/response';
import type { UriType } from '~/model/GlobalTypes';
import { redirect, usePathname } from '@/i18n/routing';
import { removeCookie } from '~/utils/utils';
import appConst, { CookieName } from '~/const/appConst';

export interface ApiError {
  '@id': UriType;
  'hydra:description': string;
  'hydra:title': string;
  error_description: string;
}

export function useError() {
  const pathname = usePathname();
  const locale = useLocale();

  const [error, setError] = useState<ApiError>();

  const entityPathRegex = /^\/(interactions|projects)\/(\d+)$/;

  const shouldNotRedirectOnBlocked = error?.['hydra:description'] === 'The account is blocked' || entityPathRegex.exec(pathname);
  const status = getErrorCodeFromUri(error?.['@id']);

  useEffect(() => {
    if (!shouldNotRedirectOnBlocked && status === 403) {
      removeCookie(CookieName.ACCESS_TOKEN);
      removeCookie(CookieName.REFRESH_TOKEN);
      window.location.reload();
    }

    if (status === 503) {
      redirect({ href: appConst.staticRoutes.maintenance, locale });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return { error, setError };
}
