import type { ReactElement } from 'react';

export const ProjectTabsSkeleton = ({
  children,
}: {
  children: ReactElement;
}) => {
  return (
    <div className="project-tabpanes__tabpane project-tabpanes__tabpane--active">
      {children}
    </div>
  );
};

const SkeletonItem = () => (
  <div className="projects-list-scroller__item">
    <article className="project-item project-item--horizontal">
      <span className="project-item__link">
        <header className="project-item__header">
          <span className="project-item__media">
            <span className="skeleton__media vtmn-skeleton" />
          </span>
        </header>
      </span>
      <footer className="project-item__body">
        <span className="vp-body-s">
          <span className="skeleton__item vtmn-skeleton" />
        </span>
      </footer>
    </article>
  </div>
);

export const ProjectsListSkeleton = () => (
  <div
    className="skeleton-template projects-list-skeleton"
    data-testid="projects-list-skeleton"
  >
    <div className="projects-list">
      {[...Array(12).keys()].map(key => <SkeletonItem key={key} />)}
    </div>
  </div>
);
