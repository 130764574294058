'use client';

import { useTranslations } from 'next-intl';
import classNames from 'classnames';
import { useSearchParams } from 'next/navigation';
import { OptionsStatusValueType } from '@/app/[locale]/projects/model';
import { usePathname, useRouter } from '@/i18n/routing';
import { useProjectsContext } from '@/app/components/Provider';

export function ProjectsFiltersStatus() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const t = useTranslations('PROJECTS.FILTERS');

  const { isFetching } = useProjectsContext();

  const handleClick = (statusSelected: OptionsStatusValueType) => {
    router.push(`${pathname}?status=${statusSelected}`);
    scrollTo(0, 0);
  };

  return (
    <ul>
      <li>
        <button
          type="button"
          className={classNames('vtmn-chip', {
            'vtmn-chip--selected': searchParams.get('status') === OptionsStatusValueType.all || !searchParams.get('status'),
            'vtmn-chip--disabled': isFetching,
          })}
          onClick={() =>
            handleClick(OptionsStatusValueType.all)}
          disabled={isFetching}
        >
          {t('ALL')}
        </button>
      </li>
      <li>
        <button
          type="button"
          className={classNames('vtmn-chip', {
            'vtmn-chip--selected':
            searchParams.get('status') === OptionsStatusValueType.followed,
            'vtmn-chip--disabled': isFetching,
          })}
          onClick={() =>
            handleClick(OptionsStatusValueType.followed)}
          disabled={isFetching}
        >
          {t('FOLLOWED')}
        </button>
      </li>
      <li>
        <button
          type="button"
          className={classNames('vtmn-chip', {
            'vtmn-chip--selected': searchParams.get('status') === OptionsStatusValueType.user,
            'vtmn-chip--disabled': isFetching,
          })}
          onClick={() =>
            handleClick(OptionsStatusValueType.user)}
          disabled={isFetching}
        >
          {t('CONTRIBUTIONS')}
        </button>
      </li>
    </ul>
  );
}
