import type { ContributionType } from './ContributionType';
import type { CriteriaType, TargetCriteriaType } from './CriteriaType';
import type { LocaleType, UriType } from './GlobalTypes';
import type { MediaType } from './MediaType';
import type { ProjectType } from './ProjectType';
import type { ReactionsRepartitionType } from './ReactionsRepartitionType';
import type { SportType } from './SportType';
import type { TimeSlotType } from './TimeSlotType';
import type { UserContribStatusCountType } from './UserContribStatusCountType';
import type { UserType } from './UserType';
import type { TranslationType } from './TranslationType';
import type { ReactionType } from './ReactionType';

export enum InteractionTypes {
  announcement = 'Announcement',
  interaction = 'Interaction',
  selection = 'Selection',
  survey = 'Survey',
  thread = 'Thread',
}

export enum InteractionTags {
  DISCUSSION = 'DISCUSSION',
  EVENT = 'EVENT',
  INTERVIEW = 'INTERVIEW',
  NEWS = 'NEWS',
  PRODUCT_TEST = 'PRODUCT_TEST',
  PRODUCT_TEST_ONSITE = 'PRODUCT_TEST_ONSITE',
  PRODUCT_TEST_REMOTE = 'PRODUCT_TEST_REMOTE',
  RESULT = 'RESULT',
  ROUNDTABLE = 'ROUNDTABLE',
  SURVEY_FEEDBACK = 'SURVEY_FEEDBACK',
  SURVEY_QUANTITATIVE = 'SURVEY_QUANTITATIVE',
  USER_TEST = 'USER_TEST',
  WORKSHOP = 'WORKSHOP',
}

export type InteractionTagsKeys = keyof typeof InteractionTags;

export interface InteractionType {
  '@id'?: string;
  '@type'?: InteractionTypes;
  allow_to_send_to_target_mail?: boolean;
  at_home?: boolean;
  body: string;
  can_have_multiple_contributions: boolean;
  contribution?: ContributionType[];
  contributions_count?: number;
  contributors_count?: number;
  created_at?: string;
  created_by?: any;
  criteria_choices?: string[];
  cron_job?: string[];
  current_user_interaction_status?: CurrentUserInteractionStatus;
  current_user_contribution?: {
    count_per_status: UserContribStatusCountType;
    reaction: ReactionType;
  };
  end_at?: string;
  event_end_at?: string;
  event_start_at?: string;
  for_children?: boolean;
  for_internals?: boolean;
  has_child: boolean;
  id?: number;
  is_there_targeted_users?: boolean;

  media: MediaType;
  only_for?: number;
  only_one_time_slot?: boolean;
  original_locale: LocaleType;
  owner?: UserType;
  parent?: InteractionType;
  project?: ProjectType;
  random_sort?: boolean;
  reactions_repartition?: ReactionsRepartitionType;
  result_visible_on_front: boolean;
  sections?: UriType[];
  sports?: SportType[];
  start_at?: string;
  status?: string;
  tag: InteractionTags;
  target_criteria?: TargetCriteriaType[];
  target_mail_send?: boolean;
  time_slots?: TimeSlotType[];
  title: string;
  translations?: TranslationType;
  video_url?: string;
}

export interface CurrentUserInteractionStatus {
  criterion_status: {
    collected: CriteriaType[];
    mismatched: CriteriaType[];
    missing_mandatory: CriteriaType[];
    requested: CriteriaType[];
  };
  user_status: 'MATCH' | 'MISMATCH' | 'PENDING';
  user_id: number;
}

export interface UserInteractionStatusType {
  '@id'?: UriType;
  current_user_can_participate: boolean;
  current_user_contributions_per_status_count?: UserContribStatusCountType;
  current_user_contributor?: boolean;
  current_user_mismatched_target_criteria?: TargetCriteriaType[];
  current_user_missing_target_criteria?: TargetCriteriaType[];
  current_user_requested_criteria?: TargetCriteriaType[];
  current_user_reaction?: string;
  user_children_contributions_per_status_count?: UserContribStatusCountType;
  current_user_in_email_targeting?: boolean;
}
