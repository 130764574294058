'use client';

import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { useState } from 'react';
import { getResizedPictureUrl } from '~/utils/utils';

export const ResizedImg = ({
  src,
  format,
  fallBackFormat = '',
  ...rest
}: ResizedImgPropsType & ImageProps) => {
  const [errorImg, setErrorImg] = useState(false);

  const fallbackImage = errorImg && (
    <Image
      data-testid="fallback"
      src={src ? getResizedPictureUrl(src, fallBackFormat) : ''}
      alt=""
      {...rest}
    />
  );

  const resizedImage = (
    <Image
      data-testid="resized"
      src={getResizedPictureUrl(src as string, format)}
      onErrorCapture={() => setErrorImg(true)}
      alt=""
      {...rest}
    />
  );

  return fallbackImage || resizedImage;
};

export interface ResizedImgPropsType {
  src: string;
  format: string;
  fallBackFormat?: string;
}
