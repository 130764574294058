import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale } from 'next-intl';
import { useApiErrorContext } from '@/app/components/Provider';
import {
  RequestStatus,
  useRequestState,
} from '~/hooks/request/useRequestState';
import type { CriteriaType } from '~/model/CriteriaType';
import { uniqueKeepLast } from '~/utils/filter/filter';
import type { LocaleType } from '~/model/GlobalTypes';
import { fetchCollection } from '@/app/actions/server';
import { CriteriaVisibilityStatus } from '~/const/appConst';
import type { SearchParams } from '@/app/utils/request/url';
import { AcceptLanguage } from '~/const/locale';

export function useCriteria({
  criteriaFilter,
}: {
  criteriaFilter?: SearchParams;
}) {
  const locale = useLocale() as LocaleType;

  const [criteria, setCriteria] = useState<CriteriaType[]>();

  const { status, handleError, handleSuccess, startRequest }
    = useRequestState();
  const { setError } = useApiErrorContext();

  const uriList = useMemo(() => {
    return criteria?.map(criterion => criterion['@id']);
  }, [criteria]);

  const getCriteria = useCallback(
    async (filter = criteriaFilter) => {
      if (!filter)
        return;

      startRequest();
      const response = await fetchCollection<CriteriaType>({
        pathname: 'criteria',
        headers: {
          acceptLanguage: AcceptLanguage[locale],
        },
        revalidate: 0,
        searchParams: {
          'not_in[visibility]': CriteriaVisibilityStatus.HIDDEN,
          'not_in[type]': 'PARTICIPANT',
          ...filter,
        },
        unpaginate: true,
      });

      if (response['hydra:description']) {
        handleError();
        return setError(response);
      }

      setCriteria(current =>
        uniqueKeepLast([...(current || []), ...response['hydra:member']], '@id'),
      );
      handleSuccess();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [criteriaFilter?.length],
  );

  useEffect(() => {
    getCriteria();
  }, [getCriteria]);

  return {
    criteria,
    uriList,
    isFetching: status === RequestStatus.PENDING,
    getCriteria,
  };
}
