'use client';

import { useEffect, useMemo } from 'react';
import { VpButton, VpIcon, VpIconButton } from '@vtmn-play/react/headless';
import { useTranslations } from 'next-intl';
import { Link } from '@/i18n/routing';
import appConst from '~/const/appConst';
import { isAuthenticatedAsMainUser, isMainUser } from '~/utils/user/user';
import { useCriteriaCompletion } from '~/hooks/criteria/useCriteriaCompletion';
import { Loader } from '@/app/components/Loader';
import { useAuthenticatedContext } from '@/app/components/Provider';
import { useUserCriteria } from '@/app/hooks/useUserCriteria';
import { CompleteProfileBannerWrapper } from '~/components/commons/CompleteProfileBanner/CompleteProfileBannerWrapper';
import { useCriteria } from '@/app/hooks/useCriteria';
import { useUserSports } from '@/app/hooks/useUserSports';

export const CompleteProfileBannerHomepage = () => {
  const t = useTranslations();

  const { userSports } = useUserSports();
  const { user, isAuthenticated, isAuthenticating, redirectToLogin } = useAuthenticatedContext();

  const { main_criterias, sports_profile } = appConst.staticRoutes;

  const { uriList: mainUserCriteriaUriList } = useUserCriteria({
    userCriteriaFilter: { 'criterion.main': 'true' },
  });

  const { uriList: mandatoryUserCriteriaUriList } = useUserCriteria({
    userCriteriaFilter: { 'criterion.mandatory': 'true' },
  });

  const { uriList: mainCriteriaUriList, getCriteria: getMainCriteria }
    = useCriteria({});

  const {
    uriList: mandatoryCriteriaUriList,
    getCriteria: getMandatoryCriteria,
  } = useCriteria({});

  const { missing: missingMain } = useCriteriaCompletion(
    mainCriteriaUriList,
    mainUserCriteriaUriList,
  );

  const { missing: missingMandatory } = useCriteriaCompletion(
    mandatoryCriteriaUriList,
    mandatoryUserCriteriaUriList,
  );

  const missing = useMemo(() => {
    return (
      !!missingMain // Main criteria = criteria associated to the user for product testing (shoe size, clothes size, etc.)
      && !!missingMandatory && [
        ...missingMain,
        ...missingMandatory,
      ] // Mandatory criteria = associated to the user profile (genre, department , birthday)
    );
  }, [missingMain, missingMandatory]);

  const count = useMemo(() => {
    if (!userSports?.['hydra:member'])
      return;

    return (
      +(!userSports?.['hydra:member']?.length ? 1 : 0) // 1 if no favorite sports
      + missing?.length
    );
  }, [
    userSports,
    missing,
  ]);

  useEffect(() => {
    if (isMainUser(user)) {
      Promise.all([
        getMainCriteria({ main: 'true' }),
        getMandatoryCriteria({ mandatory: 'true' }),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (
    isAuthenticating
    || (
      isAuthenticatedAsMainUser(user, isAuthenticated)
      && (typeof count !== 'number' || Number.isNaN(count))
    )
  ) {
    return (
      <CompleteProfileBannerWrapper isHomepage>
        <VpIconButton data-testid="complete-profile-loading" disabled>
          <Loader loading inverted />
        </VpIconButton>
      </CompleteProfileBannerWrapper>
    );
  }

  return (
    <CompleteProfileBannerWrapper isHomepage>
      {isAuthenticatedAsMainUser(user, isAuthenticated) && count > 0 && (
        <Link
          data-testid="complete-profile-hero-with-count"
          className="vp-button button-wrapper-link"
          href={main_criterias}
          prefetch={false}
        >
          <span className="vp-button__label" data-part="label">
            <div data-part="start-slot" data-testid="icon-timer">
              <VpIcon name="timer" />
            </div>
            {t('HOMEPAGE.COMPLETE_PROFILE.BUTTON', {
              count,
            })}
          </span>
        </Link>
      )}
      {isAuthenticatedAsMainUser(user, isAuthenticated) && count === 0 && (
        <Link
          data-testid="complete-profile-hero"
          className="vp-button button-wrapper-link"
          href={sports_profile}
          prefetch={false}
        >
          <span className="vp-button__label" data-part="label">
            <div data-part="start-slot" data-testid="icon-sparkles">
              <VpIcon name="sparkles" />
            </div>
            {t('HOMEPAGE.COMPLETE_PROFILE.BANNER.BUTTON')}
          </span>
        </Link>
      )}
      {!isAuthenticatedAsMainUser(user, isAuthenticated) && (
        <VpButton
          data-testid="login-or-create-account"
          onClick={async () => await redirectToLogin()}
        >
          {t('GLOBAL.LOGIN_OR_CREATE_ACCOUNT')}
        </VpButton>
      )}
    </CompleteProfileBannerWrapper>
  );
};
