import { VpIcon } from '@vtmn-play/react/headless';
import classNames from 'classnames';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import { useTranslations } from 'next-intl';
import type { OptionSportsType } from '@/app/[locale]/projects/model';
import { capitalizeString } from '~/utils/utils';

const { Option } = components;

export const IconOption = (props: OptionProps<OptionSportsType>) => {
  const t = useTranslations('SPORTS_FILTER');
  const { data, isSelected } = props;

  return (
    <Option {...props}>
      <span
        className={classNames('icon-option', {
          'icon-option--selected': isSelected,
        })}
      >
        {isSelected && <VpIcon name="check" size={16} />}
      </span>
      {' '}
      {data.isFavourite
        ? (
          <span
            className="projects-filter__select-label"
            title={t('ALREADY_FAVOURITE')}
          >
            {capitalizeString(data.label)}
            <VpIcon name="star" size={20} />
          </span>
          )
        : (
            data.label
          )}
    </Option>
  );
};
