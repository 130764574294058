'use client';

import Script from 'next/script';
import { DatadogRUM } from './DatadogRUM';
import { Medallia } from './Medallia';

export function ThirdPartyScript() {
  return (
    <>
      {process.env.GOOGLE_TAG_MANAGER && (
        <Script id="gtm">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER}');`}
        </Script>
      )}
      <DatadogRUM />
      <Medallia />
    </>
  );
}
