'use client';

import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslations } from 'next-intl';
import { useAuthenticatedContext } from '../Provider/AuthenticatedProvider';
import { useApiErrorContext } from '../Provider';
import { Link } from '@/i18n/routing';
import { GenericDialog } from '~/components/commons/genericDialog/GenericDialog';
import { RequestStatus, useRequestState } from '~/hooks/request/useRequestState';
import { fetchItem } from '@/app/actions/server';
import type { UserType } from '~/model/UserType';

export const CGUDialog = ({ isOpen }: { isOpen: boolean }) => {
  const t = useTranslations('CGU_DIALOG');
  const { setError } = useApiErrorContext();
  const { setUser } = useAuthenticatedContext();
  const { startRequest, handleError, handleSuccess, status } = useRequestState();

  const [checked, setChecked] = useState(false);

  const handleChange = (c: ChangeEvent<HTMLInputElement>) => {
    setChecked(c.target.checked);
  };

  const onUpdate = async () => {
    startRequest();
    const response = await fetchItem<UserType>({
      pathname: 'users/me/accept-current-terms-of-use',
      body: {},
      method: 'PUT',
    });

    const { term_of_use_accepted, 'hydra:description': message } = response;

    if (message) {
      handleError();
      setError(response);
    }
    setUser(prev => ({ ...prev, term_of_use_accepted }));
    handleSuccess();
  };

  const isUpdating = status === RequestStatus.PENDING;

  const checkboxForm = (
    <div className="control-stacked">
      <div className="form-group checkbox">
        <input
          type="checkbox"
          className="vtmn-checkbox"
          id="check-cgu"
          name="check-cgu"
          checked={checked}
          onChange={handleChange}
        />
        <label htmlFor="check-cgu">
          <span>
            {t('CONTENT')}
            {' '}
            <Link
              target="_blank"
              href="/general-terms-of-service"
              prefetch={false}
            >
              {t('CONTENT_2')}
            </Link>
            {' '}
            {t('CONTENT_3')}
            {' '}
            <Link
              target="_blank"
              href="/special-terms-of-service"
              prefetch={false}
            >
              {t('CONTENT_4')}
            </Link>
            .
          </span>
        </label>
      </div>
    </div>
  );

  return (
    <GenericDialog
      isOpen={isOpen}
      isClosable={false}
      title={t('TITLE')}
      content={checkboxForm}
      primaryButtonTitle={t('ACCEPT')}
      isPrimaryButtonDisabled={!checked || isUpdating}
      primaryButtonClick={onUpdate}
      isLoading={isUpdating}
    />
  );
};
