import { InteractionTypes } from '~/model/InteractionType';

export const uriPrefix = '/api/';

export const translationsQueryParam = (entity: string) =>
  `groups[]=${entity}_translations`;

export const api = {
  ANNOUNCEMENTS: 'announcements',
  ANSWERS: 'answers',
  ANSWER_CHOICES: 'answer_choices',
  ANSWER_USERS: 'answer_users',
  AVAILABLE_LOCALES: 'available_locales',
  CHILDREN: 'children',
  CHOICES: 'choices',
  COMMENTS: 'comments',
  CONCLUSIONS: 'conclusions',
  CONTACT: 'contact',
  CONTACT_SITE_MANAGER: 'contact_site_managers',
  CONTRIBUTIONS: 'contributions',
  CRITERIAS: 'criteria',
  CRITERIA_CHOICES: 'criterion_choices',
  CRITERIA_CHOICE_USERS: 'criterion_choice_users',
  DATA: 'data',
  FOLLOWED_UP_PROJECTS: 'followed_up_projects',
  GRID_QUESTIONS: 'grid_questions',
  GUEST_ACCESS: 'guest_access',
  GUEST_TOKEN: 'guest_users/token',
  GUEST_USERS: 'guest_users',
  INTERACTIONS: 'interactions',
  INTERACTION_RESULTS: 'interaction_results',
  ME: 'me',
  MEDIA: 'media',
  SIGNED_URL: 'signed_url',
  MATCHING_USER_CRITERIA: 'matching_user_criteria',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_COUNT: 'notifications/count-not-seen',
  ORIGIN: 'FO',
  PROJECTS: 'projects',
  PROJECT_TAGS: 'project_tags',
  QUESTIONS: 'questions',
  RATING: 'rating',
  REACTIONS: 'reactions',
  RESULT: 'result',
  RESULTS: 'results',
  SECTIONS: 'sections',
  SELECTED_TIME_SLOTS: 'selected_time_slots',
  SELECTIONS: 'selections',
  SEND_MAIL: 'send_mail',
  SESSIONS: 'sessions',
  SPORTS: 'sports',
  STEP_TYPES: 'step_types',
  SUB_QUESTIONS: 'sub_questions',
  SUBSCRIPTION: 'subscription',
  SURVEYS: 'surveys',
  STREAM_TOKEN: 'stream_token',
  TERMS_OF_USES: 'terms_of_uses',
  THREADS: 'threads',
  TIME_SLOTS: 'time_slots',
  TRANSLATION: 'translation',
  UNSUBSCRIBE: 'unsubscribe',
  USERS: 'users',
  USER_REFRESH_TOKEN: 'users/refresh-token',
  USER_TOKEN: 'users/token',
  USER_INTERACTION_STATUS: 'user_interaction_status',
};

export const apiTypes = {
  CHECK: 'CHECK',
  CONDITIONAL: 'CONDITIONAL',
  DATE: 'DATE',
  EMAIL: 'EMAIL',
  FILE: 'FILE',
  GRID: 'GRID',
  INTEGER: 'INTEGER',
  PICTURE: 'PICTURE',
  RADIO: 'RADIO',
  RANKING: 'RANKING',
  RANKING_SCALE: 'RANKING_SCALE',
  RANKING_PICTURE: 'RANKING_PICTURE',
  SCALE: 'SCALE',
  SELECT: 'SELECT',
  STAR_RATING: 'STAR_RATING',
  TEXT: 'TEXT',
  VAS1: 'VAS1',
  VAS2: 'VAS2',
  CHOICES_JAR: 'CHOICES_JAR',
};

export const apiPaths = {
  ANNOUNCEMENTS: 'announcements',
  ANSWER: 'answer',
  CHOICE: 'choice',
  COMMENT: 'comment',
  CONCLUSION: 'conclusion',
  CRITERIA: 'criteria',
  CRITERIA_CHOICE: 'criterion_choice',
  INTERACTION: 'interaction',
  MEDIA: 'media',
  PROJECT: 'project',
  QUESTION: 'question',
  SECTION: 'section',
  SPORT: 'sport',
  USER: 'user',
};

export const interactionsTypes = {
  announcement: InteractionTypes.announcement,
  selection: InteractionTypes.selection,
  survey: InteractionTypes.survey,
  thread: InteractionTypes.thread,
};

export const transitionsContribution = {
  accept: 'accept',
  confirm: 'confirm',
  refuse: 'refuse',
  retract: 'retract',
  submit: 'submit',
  wait: 'wait',
};

export const statusContribution = {
  accepted: 'accepted',
  confirmed: 'confirmed',
  initialized: 'initialized',
  refused: 'refused',
  submitted: 'submitted',
  unconfirmed: 'unconfirmed',
};

export const interactionStatus = {
  not_indexed: 'not_indexed',
  indexed: 'indexed',
  trashed: 'trashed',
};

export const transitionsInteraction = {
  finish: 'finish',
  publish: 'publish',
  recover: 'recover',
  remove: 'remove',
  unpublish: 'unpublish',
};

export const transitionsStep = {
  close: 'close',
  open: 'open',
};
