import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nlBE from 'date-fns/locale/nl-BE';
import pt from 'date-fns/locale/pt';
import type { DateTimeFormatOptions } from 'next-intl';
import { InteractionTypes } from '~/model/InteractionType';

export enum entityRoutes {
  project = 'projects',
  project_tags = 'project_tags',
  announcement = 'announcements',
  interaction = 'interactions',
  question = 'questions',
  selection = 'selections',
  sports = 'sports',
  survey = 'surveys',
  thread = 'threads',
  comment = 'Comment',
}

const entityTypes = {
  ...InteractionTypes,
  project: 'Project',
  comment: 'Comment',
};

export enum ElementType {
  CONCLUSION = 'Conclusion',
  SECTION = 'Section',
  QUESTION = 'Question',
}

const staticRoutes = {
  about: '/about',
  accessibility: '/accessibility',
  children_profile: '/profile/children',
  contact: '/contact',
  digital_usage_profile: '/profile/digital-usage',
  favourite_sports: '/profile/favourite-sports',
  gts: '/general-terms-of-service',
  index: '/',
  legal: '/legal-informations',
  main_criterias: '/main-criterias',
  maintenance: '/maintenance',
  measurement_profile: '/profile/measurement',
  messages: '/messages',
  my_participations: '/projects?status=user',
  notifications: '/settings/notifications',
  personal_data: '/settings/personal-data',
  privacy: '/privacy',
  profile: '/profile',
  projects: '/projects',
  rewards_terms: '/rewards-terms',
  settings: '/settings',
  sitemap: '/sitemap',
  sports_profile: '/profile/sports-profile',
  stst: '/special-terms-of-service',
  unsubscribe: '/unsubscribe',
};

export const dynamicRoutes = {
  interactions: '/[locale]/interactions/[id]',
  projects: '/[locale]/projects/[id]',
  selections: '/[locale]/selections/[id]',
  surveys: '/[locale]/surveys/[id]',
  threads: '/[locale]/threads/[id]',
  questions: '/[locale]/threads/[id]/questions/[question-id]',
};

const namespaces = {
  namespacesRequired: ['common', 'privacy', 'sts'],
};

export enum CookieName {
  NEXT_LOCALE = 'NEXT_LOCALE',
  COCREATION_LOCALE = 'cocreation_locale',
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  BLOCKED_ACCOUNT_PATH = 'blocked_account_path',
  REDIRECT_AFTER_LOGOUT = 'redirect_after_logout_to',
}

export enum SessionItem {
  NUMBER_OF_PAGES_VIEWED = 'number_of_pages_viewed',
  GUEST_TOKEN = 'guest_token',
}

export enum RoleTypes {
  superAdmin = 'ROLE_SUPER_ADMIN',
  trainer = 'ROLE_ADMIN_TRAINER',
  adminTest = 'ROLE_ADMIN_TEST',
  adminCocrea = 'ROLE_ADMIN_COCREATION',
  readonly = 'ROLE_ADMIN_READONLY',
  user = 'ROLE_USER',
}

export enum criteriaFlagsTypes {
  USER_AGE = 'USER_AGE',
  USER_COUNTRY = 'USER_COUNTRY',
  USER_DEPARTMENT = 'USER_DEPARTMENT',
  USER_GENDER = 'USER_GENDER',
  USER_ORGANIZATION = 'USER_ORGANIZATION',
  USER_SPORT = 'USER_SPORT',
}

export enum CriteriaVisibilityStatus {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
  HIDDEN_FOR_CHILDREN = 'HIDDEN_FOR_CHILDREN',
}

export enum ComponentTranslationStatus {
  valid = 'VALID',
  draft = 'DRAFT',
  auto = 'AUTO',
}

export enum UserRewardsStatus {
  lucky = 'lucky',
  unlucky = 'unlucky',
};

const locales = {
  de,
  en: enGB,
  es,
  fr,
  it,
  nl: nlBE,
  pt,
};

const labelLocales = {
  de_DE: 'Deutsch',
  en_GB: 'English',
  es_ES: 'Español',
  fr_FR: 'Français',
  it_IT: 'Italiano',
  nl_BE: 'Nederlands',
  pt_PT: 'Português',
};

const dateFormat: Record<string, DateTimeFormatOptions> = {
  hour: {
    hour: 'numeric',
    minute: 'numeric',
  },
  weekDayDate: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  date: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  day: {
    weekday: 'long',
  },
  dayNumeric: {
    day: 'numeric',
  },
  monthNumeric: {
    day: 'numeric',
    month: 'numeric',
  },
  dateWithWeekdayFullAndMonthLong: {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
  },
  dateWithMonthLong: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  fullDate: {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  dayLongWithMonthLong: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
};

const organization = {
  decathlon: '/api/organizations/1',
};

const apiErrors = {
  missingMandatoryQuestion:
    'contribution status cannot be submit when mandatory questions are not answered',
  duplicateContribution: 'You can only contribute once',
  duplicateContributionLongDurationSurvey:
    'A contribution for this interaction with the initialized status already exists',
};

// values in betweens must have a min-width AND a max-width
export enum Breakpoints {
  S = '(max-width: 767px)',
  M = '(min-width: 768px) and (max-width: 991px)',
  L = '(min-width: 992px) and (max-width: 1279px)',
  XL = '(min-width: 1280px)',
}

export type BreakpointsTypeKeys = keyof typeof Breakpoints;

export const breakpointsKeys = new Map<Breakpoints, BreakpointsTypeKeys>(
  Object.entries(Breakpoints).map(
    ([key, value]: [BreakpointsTypeKeys, Breakpoints]) => [value, key],
  ),
);

export enum AnalyticEvents {
  COMPLETED_PROFILE = 'completed_profile',
  CONNECTED = 'connected',
  CROSS_SELL = 'cross_sell_click',
  FORM_SUBMIT = 'form_submit',
  SHARE_PROJECT = 'share_project',
  SIGN_UP = 'sign_up',
  RESULTS_DISPLAYED = 'results_displayed',
  FOLLOW_UP = 'follow_click',
}

const appConst = {
  apiErrors,
  criteriaFlagsTypes,
  dateFormat,
  entityRoutes,
  entityTypes,
  labelLocales,
  locales,
  namespaces,
  organization,
  organizationName: 'Decathlon',
  paginationItemsPerPage: 30,
  staticRoutes,
};

export default appConst;
