'use client';

import { useTranslations } from 'next-intl';
import { useAuthenticatedContext } from '@/app/components/Provider';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';
import { Link } from '@/i18n/routing';
import appConst from '~/const/appConst';

export function FooterHelp() {
  const t = useTranslations('NAVIGATION');
  const { user, isAuthenticated } = useAuthenticatedContext();

  return isAuthenticatedAsMainUser(user, isAuthenticated)
    ? (
      <section>
        <h3 className="vp-body-s footer__title">{t('HELP')}</h3>
        <nav aria-label={t('HELP')} role="navigation">
          <ul>
            <li>
              <Link
                className="vp-link vp-link--on-brand"
                href={appConst.staticRoutes.contact}
                rel="noindex, nofollow"
                prefetch={false}
              >
                <span className="vp-link__label" data-part="label">
                  {t('CONTACT')}
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </section>
      )
    : null;
}
