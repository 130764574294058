import { useTranslations } from 'next-intl';

export const GuestModeDescription = () => {
  const t = useTranslations('GUEST_MODAL');

  return (
    <>
      <span>{t('DESCRIPTION_1')}</span>
      <span>{t('DESCRIPTION_2')}</span>
    </>
  );
};
